import React, { useState, useEffect }  from 'react'

import img from '../images/IMG9.jpg'
import img2 from '../images/img1.jpg'
import img3 from '../images/img6.jpg'


export default function Banner() {

  const images = [
    img,
    img2,
    img3
  ];

const [currentIndex, setCurrentIndex] = useState(0);

useEffect(() => {
const interval = setInterval(() => {
setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
}, 5000); // Change every 5 seconds
return () => clearInterval(interval); // Cleanup interval on component unmount
}, [images.length]);

  return (
    <div className=''>
   
        {/* kenburns-top-left */}
      <div className='text-slate-900 shadow ' id='nav-bar' style={{backgroundImage: `url(${images[currentIndex]})` }}>
     
     
      {/* <div id='banner-text' className='sticky-top'>
          <h1 >xminger advertising limited</h1>
        </div> */}
      
      </div>
      
      
     
    </div>
  )
}
