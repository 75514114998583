// import React, { useState, useEffect }  from 'react'
import React from "react"
// import { Link } from 'react-router-dom';
import YouTube from "react-youtube"

import img1 from '../projects_img/IMG_6026.jpg'
import img2 from '../projects_img/IMG_6054.jpg'
import img3 from '../projects_img/IMG_6081.jpg'
import img4 from '../projects_img/IMG_6086.jpg'
import img5 from '../projects_img/IMG_6095.jpg'
import img6 from '../projects_img/IMG_6104.jpg'
import img7 from '../projects_img/IMG_6110.jpg'
import img8 from '../projects_img/IMG_6116.jpg'

// import img2 from '../images/img6.jpg'


function Project() {

  const videoOptions = { 
    // height:'700',
    width:'100%',
    playerVars: {
      autoplay:0,
    }
  }


  return (
    
    <div className="pb-0" id="project">
      <h4 className='content-title text-center font-bold text-3xl my-5 bg-light py-4'>Featured Projects</h4>
      <div className='container-fluid'>
      <div className="row my-2 "  data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">

        <div className="col-md-3 mb-2 img-zoom"><a href="https://davdery.com/projects_img/IMG_6026.jpg" target="_blank" rel="noopener noreferrer"><img className="img-radius shadow" src={img1} alt="" /></a></div>
        <div className="col-md-3  mb-2 img-zoom"><a href="http://davdery.com/projects_img/IMG_6054.jpg" target="_blank" rel="noopener noreferrer"><img className="img-radius shadow" src={img2} alt="" /></a></div>
        <div className="col-md-3  mb-2 img-zoom"><a href="http://davdery.com/projects_img/IMG_6081.jpg" target="_blank" rel="noopener noreferrer"><img className="img-radius shadow" src={img3} alt="" /></a></div>
        <div className="col-md-3 img-zoom"><a href="http://davdery.com/projects_img/IMG_6086.jpg" target="_blank" rel="noopener noreferrer"><img className="img-radius shadow" src={img4} alt="" /></a></div>
      </div>
      <div className="row mb-2"  data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div className="col-md-3 mb-2  img-zoom"><a href="http://davdery.com/projects_img/IMG_6095.jpg" target="_blank" rel="noopener noreferrer"><img className="img-radius shadow" src={img5} alt="" /></a></div>
        <div className="col-md-3 mb-2 img-zoom"><a href="http://davdery.com/projects_img/IMG_6104.jpg" target="_blank" rel="noopener noreferrer"><img className="img-radius shadow" src={img6} alt="" /></a></div>
        <div className="col-md-3 mb-2 img-zoom"><a href="http://davdery.com/projects_img/IMG_6110.jpg" target="_blank" rel="noopener noreferrer"><img className="img-radius shadow" src={img7} alt="" /></a></div>
        <div className="col-md-3 img-zoom"><a href="http://davdery.com/projects_img/IMG_6116.jpg" target="_blank" rel="noopener noreferrer"></a><img className="img-radius shadow" src={img8} alt="" /></div>
      </div>
      <div className="text-center mt-5">  
        {/* <button className="btn btn-outline-danger shadow"><Link to="/projects">Explore More</Link></button>*/}
        <button className="btn btn-outline-danger shadow"><a href="https://youtube.com/@xmingeradvertising7690?si=BzZdjviMlH6BgvQv">Explore More</a></button>
      </div>
    </div>
    <div className="">
      <div className="row">
        <div className="col-12 mx-auto my-3 text-center" >

        <YouTube videoId="sLiEaLjwJI8" opts={videoOptions} id="youtube_vd"/>
          <button className="btn btn-outline-danger mt-4 "><a href="https://youtube.com/@xmingeradvertising7690?si=BzZdjviMlH6BgvQv">Watch More</a></button>
        </div>
      </div>
        
      </div>
    </div>
    
  )
}

export default Project
