import React from 'react'
import Carousel from './Carousel';

// import logo from "../images/revolu.jpg"
import logo1 from "../images/img2.jpg"
import logo2 from "../images/img4.jpg"
import Kwame from "../images/xminger-bill.webp"
import Nkrumah from "../images/xmin_kwamw.webp"
import { Link } from 'react-router-dom';



export default function Home() {

  const images = [
    // logo+'?text=Slide+1',
    logo1+'?text=Slide+2',
    // logo2+'?text=Slide+3',
  ];

  return (
    <div id='blog'>

<h1 className="text-3xl font-bold text-center bg-light py-4 content-title">News & Updates</h1>
      <div className="container mx-auto">
       
        <div className="row">
          <div className="col-md-7" id='news-main'>
          <Carousel images={images}/>
          <div className='news-text'>
          <h5 className='font-bold mt-5 '>REVOLUTIONIZING THE ADVERTISING LANDSCAPE IN GHANA WITH WEST AFRICA’S SECOND LARGEST BILLBOARD.</h5>
          <p className='text-x pt-4 body-ellipsis text-justify'>Xminger Advertising is a leading force in the advertising industry in Ghana, making waves with it’s groundbreaking campaigns and innovative approaches. In a remarkable feat, the company has recently erected west Africa’s second largest billboard measuring 9m x 40m in the commercial area of Adum cocoa board, located in the heart of Kumasi. In an ambitious move to solidify it’s presence in the Ghanaian advertising landscape, Xminger embarked on a project to erect a monumental billboard in the Adum cocoa board area, a prime location known for it’s traffic and strategic visibility. The strategic placement of the billboard ensures maximum exposure to vast and diverse audience, making it an ideal platform for brands to showcase their products and services. The sheer size of the billboard guarantees high visibility catching the attention of thousands of passersby, creating significant brand awareness and recall. The billboard is equipped with a lighting system which illuminates during the night adding to it’s ambience. Xminger Advertising is keen on innovation and creativity as we look to be at the forefront of the Ghanaian advertising industry..</p>
          <button className='btn btn-outline-danger my-3'><Link to="/news_one">Read More</Link></button>
          </div>
         
          </div>
          {/* Small Post */}
          <div className="col-md-5  news-divs"  data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className="row text-center">
              <div className="col-12 news-card">
              <div className="row">
                <div className='col-4'>
                <img className='news-card-img' src={Nkrumah} alt="" />
                </div>
                <div className='col-8 news-text'>
                <h5 className='font-bold mt-2 text-ellipsis'>KWAME NKRUMAH UNIVERSITY OF SCIENCE AND TECHNOLOGY STUDENTS EXPLORE XMINGER ADVERTISING'S OPERATIONS</h5>
                <p className='text-x pt-1 body-ellipsis text-justify'>In an effort to provide students with hands-on industry experience, a group of students from the department of Language and Communication Sciences at the Kwame Nkrumah University of Science and Technology (KNUST) embarked on a visit to Xminger Advertising. </p>
                 <button className='btn btn-outline-danger my-2'><Link to="/news_two">Read More</Link></button>
                </div>
                </div>
              </div>
              <div className="col-12 pt-4">
                <div className="row">
                <div className='col-4'>
                <img className='news-card-img' src={Kwame} alt="" />
                </div>
                <div className='col-8 news-text'>
                <h5 className='font-bold mt-2  text-ellipsis'>XMINGER BILLBOARD: A HERORIC GUARDIAN AT ANLOGA JUNCTION</h5>
                <p className='text-x pt-1 body-ellipsis text-justify'>Situated in the heart of Anloga Junction, one of Xminger’s billboards which is strategically positioned to capture the attention of a large number of motorist and pedestrians passing by each day became more than just a marketing tool on December 7, 2023. </p>
                <button className='btn btn-outline-danger my-2'><Link to="/news_three">Read More</Link></button>
                </div>
                </div>
              
               
              </div>
              <div className="col-12 py-4">
              <div className="row">
                <div className='col-4'>
                <img className='news-card-img' src={logo2} alt="" />
                </div>
                <div className='col-8 news-text'>
                <h5 className='font-bold mt-2 text-ellipsis'>25 YEARS OF REIGN: CELEBRATING THE REMARKABLE LEGACY OF ASANTEHENE OTUMFUOR OSEI TUTU II</h5>
                <p className='text-x pt-1 body-ellipsis text-justify'>We celebrate Asantehene, Otumfour Osei Tutu II. ‘Otumfour wo nkwa so’. It’s been 25 years since His Royal Majesty Otumfour Osei Tutu II was enthroned as the King over all of Asanteman and as the sole custodian of the Asante Kingdom, its powers, the land, its customs and traditions and the people at large. </p>
                <button className='btn btn-outline-danger my-2 '><Link to="/news_four">Read More</Link></button>
                </div>
                
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}
