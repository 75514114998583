import React from 'react'
import 'animate.css';
import img1 from '../images/service2.jpg'
import img2 from '../images/service1.jpg'
import img3 from '../images/service4.jpg'
import { Link } from 'react-router-dom';


function Service() {
  return (
    <div className='mt-0' id='services'>
       <h1 className='text-center content-title text-3xl font-bold bg-light py-4'>Our Services</h1>

       <div className="container  mx-auto">
        <div className="row"  data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
          <div className="col-md-4 pb-5">
            <div className="card shadow">
              <div className="">
              <img src={img1} alt="" className='card-img img-zoom'/>
              </div>
              <div className="card-head px-3">

              <h4 className='card-title font-bold py-5 text-2xl text-center'><button className='bg-red-800 text-slate-200 rounded-xl px-4'>Billboard Rental</button></h4>
              <hr  className='text-slate-500'/>
             <div className='pt-3  service-txt'>
              <p className='text-justify'>At Xminger Advertising, we have built an extensive network of over 1,000 high-quality billboards strategically placed across Ghana. Our boards are situated in prime locations with heavy vehicular and foot traffic, ensuring that your advertising campaigns reach the widest possible audience and deliver maximum return on your investment.</p>
             </div>
             <div className='text-center py-4'>
             <button className='btn btn-outline-danger my-2 img-zoom'><Link to="/billboard_rentals">Read More</Link></button>
             </div>
           
              </div>
             
            </div>
           
          </div>

          <div className="col-md-4 pb-5">
          <div className="card shadow">
              <div className="card-bo">
              <img src={img3} alt="" className='card-img img-zoom'/>
              </div>
              <div className="card-head px-3">
              <h4 className='card-title font-bold py-5 text-2xl text-center'><button className='bg-red-800 text-slate-200 rounded-xl px-4'>Large Format Printing</button></h4>
             
              <hr  className='text-slate-500'/>
              <div className='pt-3 service-txt'>
              <p className='text-justify'>At Xminger Advertising, we take pride in offering the best printing services for all your outdoor media needs. Our commitment to using the latest high-tech machines and premium quality materials ensures that your billboard artworks will maintain their vibrant, eye-catching appearance for years to come</p>
                
              </div>
              <div className='text-center py-4'>
             <button className='btn btn-outline-danger my-2 img-zoom'><Link to="/large_printing">Read More</Link></button>
             </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
          <div className="card shadow">
              <div className="card-b">
              <img src={img2} alt="" className='card-img img-zoom'/>
              </div>
              <div className="card-head px-3">
              <h4 className='card-title font-bold py-5 text-2xl text-center'><button className='bg-red-800 text-slate-200 rounded-xl px-4'>Advertising Consultancy</button></h4>
              <hr  className='text-slate-500'/>
              <div className='pt-3 service-txt'>
              <p className='text-justify'>At Xminger Advertising, One of our primary role is to advise clients on how to effectively utilize billboard advertising to achieve their marketing objectives. This involves understanding the client's target audience, goals, and budget, and then developing a tailored strategy that maximizes the impact of billboard campaigns</p>
              </div>
              <div className='text-center py-4'>
             <button className='btn btn-outline-danger my-2 img-zoom'><Link to="/consultancy">Read More</Link></button>
             </div>
              </div>
            </div>
          </div>
        </div>
       </div>



       {/* old structure */}
       {/* <div className='container-fluid' >
        <div className="row">
          <div className="col-md-8 mx-auto">
          <p className='h5 text-slate-700 px-3'>Xminger has billboards situated at prime locations across the country. They come in various sizes and are designed to capture and captivate the viewer. We have selected the best locations in various towns in the region to ensure maximum exposure and brand building. Our advertising spaces are rented to clients who will display their visuals for a standard 12 months period or part of. Space rental renewals must be confirmed at least 30 days before expiration dates</p>
      
          </div>
        </div>
      
      <div className="containerq mt-12 text-center">
        <div className="row">
          <div className='service-card col-md-6'>
          <div className="service-head ">
              <h4>Billboard Rentals</h4>
             
            </div>
          <div>
              <p className='service-text'>With over 1000 Billboards across Ghana, our boards are strategically placed at high traffic areas (Vehicular and Foot) to help expand our clients advertising campaign reach and also get a return on their investments</p>
           <button className='btn shadow btn-outline-light px-4 my-5 drop-shadow-2xl  hover:animate-pulse'>Read More</button>
              </div>
          </div>
          <div className="col-md-6 service_img2"> </div>
        </div>

        <div className="row my-4">
        <div className="col-md-6  service_img3"> </div> 

        <div className="col-md-6 service-card bg-dark ">
        <div className="service-head ">
              
             <h4>Large format Printing</h4>
            </div>
        
        <div>
              <p className='service-text'>At Xminger Advertising, we offer the best printing services using high tech machines and quality materials. Due to this, our Billboard artworks do not fade over a long period of time after installation has been done.</p>
           <button className='btn btn-outline-light px-4 my-5 drop-shadow-2xl hover:animate-pulse'>Read More</button>
              </div>
          </div>
       
        </div>
        
        <div className="row">
        
        <div className="col-md-6 service-card" id='advercon'>
        <div className="service-head ">
              <h4>Advertising Consultancy</h4>
            </div>
              
              <div>
              <p className='service-text'>At Xminger Advertising, One of our primary role is to advise clients on how to effectively utilize billboard advertising to achieve their marketing objectives. This involves understanding the client's target audience, goals, and budget, and then developing a tailored strategy that maximizes the impact of billboard campaigns</p>
           <button className='btn btn-outline-light px-4 my-5 hover:animate-pulse'>Read More</button>
         </div>
          </div>
          <div className="col-md-6 service_img1">
    
    </div>
        </div>
      </div>
       </div> */}

    </div>
  )
}

export default Service