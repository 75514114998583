import React from 'react'
import Footer from "./Footer"
import Banner from "./Banner"
import Navbar from "./Navbar"

function AboutUs() {
  return (
    <div>
       <Navbar/>
       <Banner/>
       <div className="container">
        <div className="row py-10">
        <div className="col-md-8 mx-auto">
        <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-5'>AboutUs</h5>
               
                {/* <img src={Kwame} alt="" className='about-img' /> */}
          
            {/* <p className='text-xl pt-4'>Situated in the heart of Anloga Junction, one of Xminger’s billboards which is strategically positioned to capture the attention of a large number of motorist and pedestrians passing by each day became more than just a marketing tool on December 7, 2023. On that fateful day, a trailer suddenly lost control which posed an imminent threat to lives and property in its path. </p> */}
            {/* <p className='text-xl  pt-4'>However, fate intervened in the form of a sturdy Xminger Advertising billboard, standing tall and firm. As the trailer made its way forward, threatening to cause extensive damage, it collided with the Xminger billboard which acted as a formidable barrier making it evident how strong Xminger’s billboards are made. The robust billboard absorbed the impact of the collision, stopping the trailer’s progress and preventing it from causing further destruction.</p> */}
            <p className='text-xl  pt-4'> Xminger is a professional billboard advertising company, providing visible outdoor advertising locations/spaces that are price competitive with a reputable and inovative advertising style that markets the products and/or services of clients to ensure satisfaction. Our billboard sites are located across all sixteen(16) regions of Ghana on major high ways, market places and other strategic places prone to vehicular as well as human trafic. </p>
            <div>
            <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-5'>Our Mission</h5>
            <p className='text-xl pt-4'>
            XMinger Advertising Limited is committed to empowering our clients' success through exceptional billboard advertising services. We strive to be strategic partners, leveraging data-driven insights and creative expertiseI to design compelling campaigns that capture attention, enhance brand awareness, and generate tangible returns. Our mission is to deliver unparalleled value to our clients by consistently exceeding their expectations and maintaining the highest standards of integrity and professionalism.
            </p>
            </div>
            <div>
            <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-5'>Our Vision</h5>
            <p className='text-xl pt-4'>
            To be the premier provider of innovative, high-impact billboard advertising solutions that transform the way brands engage with their target audiences and drive measurable business results.
            </p>
            </div>
            <div>
              <button className='btn bg-slate-900 text-slate-100 mt-5'><a href="javascript:history.back()">Go Back</a></button>
            </div>  
            </div>
        </div>
        </div>
            <Footer/>
    </div>
  )
}

export default AboutUs