import React from 'react'
import Footer from "./Footer"
import Banner from "./Banner"
import Navbar from "./Navbar"

function Printing() {
  return (
    <div>
       <Navbar/>
       <Banner/>
       <div className="container">
        <div className="row py-10">
        <div className="col-md-8 mx-auto">
        <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-5'>Printing</h5>
        <div>
                <p className='py-3'>Xminger produces artworks in U.V. stabilized digital prints or pressure-sensitive vinyl as appropriate. These are guaranteed for at least two years of good visuals. </p>
                <p className='pb-3'> Alternatively, we accept the client’s artwork produced to size in U.V. stabilized digital prints on flex face. In this case, we will not accept responsibility for color fading and material failure. “Pockets” sizes must be obtained from Xminger before they are produced. </p>
              </div><div>
              <button className='btn bg-slate-900 text-slate-100 mt-5'><a href="javascript:history.back()">Go Back</a></button>
            </div>  
            </div>
        </div>
        </div> 
            <Footer/>
    </div>
  )
}

export default Printing